import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import {
  CalendarEntry,
  CALENDAR_ENTRY_TYPES,
  EventReminderResultType,
  EVENT_REMINDER_NOTIFICATION_TYPES,
  EVENT_TYPE_REMINDER_INTERVAL_HOURS,
  LowPriorityNotificationDetailType,
  NotificationType,
  PushNotificationSettingToRespect,
  Team
} from "@ollie-sports/models";

import express from "express";
import _ from "lodash";
import moment from "moment-timezone";
import { generateAndProcessNotifications, NotificationGeneratorInfo__LowPriority } from "../notification/notification.plumbing";
import { prettyCalendarEntryStrings } from "../../compute";
import { common__extractTeamNameWithSquadFromTeamAndTeamIdWithSquad } from "../common.api";
import { getLocationURL, getVerboseLocationString } from "../../compute/calendarEntry.compute";
import { dateFormatters, translateServer, translate } from "@ollie-sports/i18n";
import { isProduction } from "../../utils";

export async function eventReminders__server__sendEventReminders() {
  // SERVER_ONLY_TOGGLE
  const { serverConfig, getAppPgPool } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();

  const query = `select temp.account_id       as account_id,
            temp.email                        as email,
            temp.notification_type            as notification_type,
            temp.calendarEntry                as calendar_entry,
            temp.team                         as team,
            temp.event_type_interval          as event_type_interval
    from
      (select ap.id                                                                  as account_id,
              a.item->>'email'                                                       as email,
              ap.item -> 'settings' -> 'eventReminderSettings' -> 'notificationType' as notification_type,
              c.item                                                                 as calendarEntry,
              t.item                                                                 as team,
              case
                  when c.item ->> 'calendarEntryType' = 'game' then cast(
                      ap.item -> 'settings' -> 'eventReminderSettings' -> 'eventTypeIntervalHours' ->>
                      'game' as numeric)
                  when c.item ->> 'calendarEntryType' = 'practice' then cast(
                      ap.item -> 'settings' -> 'eventReminderSettings' -> 'eventTypeIntervalHours' ->>
                      'practice' as numeric)
                  when c.item ->> 'calendarEntryType' = 'scrimmage' then cast(
                      ap.item -> 'settings' -> 'eventReminderSettings' ->
                      'eventTypeIntervalHours' ->>
                      'scrimmage' as numeric)
                  else cast(
                      ap.item -> 'settings' -> 'eventReminderSettings' ->
                      'eventTypeIntervalHours' ->>
                      'other' as numeric) end                                       as event_type_interval
        from  mirror_calendarentry c,
              mirror_team t,
              v_team_accounts v,
              mirror_account a,
              mirror_accountprivate ap,
              mv_v_accounts_team_id_with_squad vva
        where t.id = c.item ->> 'teamId'
              and vva.account_id = ap.id
              and v.team_id = t.id
              and v.account_id = ap.id
              and a.id = ap.id
              and (c.item ->> 'forceOrgEventReminders' is null or c.item->>'forceOrgEventReminders' <> 'true')
              and (c.item ->> 'canceled' is null or c.item->>'canceled' <> 'true')
              and (c.item ->> 'isAllDay' is null or c.item->>'isAllDay' <> 'true')
              and vva.team_id_with_squad = c.item->>'teamIdWithSquad'
              and c.c_start_date_truncated_ms >= $1
              and c.c_start_date_truncated_ms < $2) temp
      where temp.event_type_interval = $3;`;

  try {
    const results = await Promise.all(
      [1, 4, 24, 48]
        .map(hours => [
          moment().add(hours, "hour").startOf("minute").subtract(1, "minute").valueOf(), // N hours minus 1 minute.
          moment().add(hours, "hour").startOf("minute").add(4, "minute").valueOf(), // N hours plus 4 minutes
          hours //N hours
        ])
        .map(params => getAppPgPool().query(query, params))
    );

    const eventReminderResults: EventReminderResultType[] = _(results)
      .map(a => a.rows)
      .flatten()
      .map(r => ({
        accountId: r.account_id,
        email: r.email,
        calendarEntry: r.calendar_entry as CalendarEntry,
        notificationType: r.notification_type as EVENT_REMINDER_NOTIFICATION_TYPES,
        team: r.team as Team,
        eventTypeIntervalHours: parseInt(r.event_type_interval) as EVENT_TYPE_REMINDER_INTERVAL_HOURS
      }))
      .value();

    await generateAndProcessNotifications({
      data: eventReminderResults.filter(a => !a.calendarEntry.eventRemindersSent?.[a.eventTypeIntervalHours]),
      generateFn: async ({ accountPrivate, data }) => {
        const ret: NotificationGeneratorInfo__LowPriority = {
          type: "lowPriorityNotification",
          notificationType: NotificationType.lowPriorityNotification,
          idempotencyKey: [
            "event-reminder",
            data.eventTypeIntervalHours,
            data.calendarEntry.id,
            data.calendarEntry.startDateTime,
            data.calendarEntry.timezone
          ].join("|")
        };

        const locale = accountPrivate.communicationLocale ?? "en-us";

        const shouldSendAppNotification =
          data.notificationType === EVENT_REMINDER_NOTIFICATION_TYPES.app ||
          data.notificationType === EVENT_REMINDER_NOTIFICATION_TYPES.both;

        const shouldSendEmail =
          data.notificationType === EVENT_REMINDER_NOTIFICATION_TYPES.email ||
          data.notificationType === EVENT_REMINDER_NOTIFICATION_TYPES.both;

        if (shouldSendAppNotification) {
          const expireAtMS = Date.now() + data.eventTypeIntervalHours * 60 * 60 * 1000;
          const date = moment.tz(data.calendarEntry.startDateTime, data.calendarEntry.timezone);

          const prettyEventTypeMap = {
            [CALENDAR_ENTRY_TYPES.game]: translate.common(locale).Game,
            [CALENDAR_ENTRY_TYPES.scrimmage]: translate.common(locale).Scrimmage,
            [CALENDAR_ENTRY_TYPES.practice]: translate.common(locale).Practice,
            [CALENDAR_ENTRY_TYPES.other]: translateServer({
              defaultMessage: "event",
              description: "A team event on the calendar, such as a birthday party, etc",
              serverLocale: locale
            }),
            secondOther: translateServer({
              defaultMessage: "Other Event",
              description: "An 'other' event on the team calendar, such as a birthday party, etc",
              serverLocale: locale
            })
          };

          const startTime = dateFormatters.t_tt_a(date, locale);
          const startDate = dateFormatters.mmmm_d_yyyy(date, locale);
          const body = translateServer(
            {
              defaultMessage: `Your {prettyEventType} is scheduled to start at {startTime} on {startDate}.`,
              serverLocale: locale
            },
            {
              prettyEventType: prettyEventTypeMap[data.calendarEntry.calendarEntryType],
              startTime,
              startDate
            }
          );

          const hoursUntil = data.eventTypeIntervalHours;

          const title = translateServer(
            {
              defaultMessage: `Event Reminder: {prettyEventType} in {hoursUntil, plural, one {# hour} other {# hours}}`,
              serverLocale: locale
            },
            {
              hoursUntil,
              prettyEventType:
                prettyEventTypeMap[
                  data.calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.other
                    ? "secondOther"
                    : data.calendarEntry.calendarEntryType
                ]
            }
          );

          const lowPriorityNotificationDetail = {
            type: LowPriorityNotificationDetailType.eventReminder,
            expireAtMS,
            routerPath: `main/events/event/${data.calendarEntry.id}`,
            title,
            body
          };

          ret.lowPriorityNotificationDetail = lowPriorityNotificationDetail;
          ret.pushNotificationData = {
            body: lowPriorityNotificationDetail.body,
            title: lowPriorityNotificationDetail.title,
            pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
            routerPath: lowPriorityNotificationDetail.routerPath,
            lowPriorityNotificationDetailType: lowPriorityNotificationDetail.type
          };

          ret.realTimeNotification = {
            e: expireAtMS
          };
        }

        if (shouldSendEmail) {
          const { contextualTitle, title, shortDate, shortTimeWithoutTimezone, endOClock, arriveOClock, shortDateTime } =
            prettyCalendarEntryStrings(data.calendarEntry, locale);

          const dateStringLong = shortDate;
          const timeStringLong = `${shortTimeWithoutTimezone}${endOClock ? ` - ${endOClock}` : ""}`;
          const calendarEntryStringShort =
            data.calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.practice
              ? _.upperFirst(title)
              : _.upperFirst(contextualTitle);

          const calendarEntryStringLong = calendarEntryStringShort;
          const arrivalTimeString = arriveOClock;
          const dateTimeStringShort = shortDateTime;

          const teamName = common__extractTeamNameWithSquadFromTeamAndTeamIdWithSquad(
            data.calendarEntry.teamIdWithSquad,
            data.team,
            false
          );

          const locationString = data.calendarEntry?.location
            ? getVerboseLocationString(data.calendarEntry.location, locale) ?? ""
            : "";
          const locationURL = data.calendarEntry.location ? getLocationURL(data.calendarEntry.location) ?? "" : "";

          ret.emailData = {
            type: "normal",
            email: data.email,
            templateId: "d-4c4f6ab994bb408abbff1d804f5d6de7",
            templateData: {
              teamName,
              arrivalTimeString: arrivalTimeString ?? translate.common(locale)["N/A"],
              calendarEntryStringLong,
              calendarEntryStringShort,
              dateStringLong,
              dateTimeStringShort,
              locationString,
              timeStringLong,
              locationURL,
              videoConferencingUrl: data.calendarEntry.videoConferencingUrl,
              unsubscribeURL: `${serverConfig.httpWebAppApiRoot}/unsubscribe/${data.accountId}/${data.calendarEntry.calendarEntryType}`
            }
          };
        }

        return ret;
      }
    });

    return { success: true, numRemindersSent: eventReminderResults.length };
  } catch (e) {
    await olliePipe.emitEvent({ type: "error-sending-event-reminders", payload: e }, { sendImmediate: true });
    return { success: false };
  }
  // SERVER_ONLY_TOGGLE
}

eventReminders__server__sendEventReminders.auth = async (r: express.Request) => {
  if (isProduction()) {
    throw new Error("Only should ever be called directly via cron jobs in production!");
  }
};

// i18n certified - complete
