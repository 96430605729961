import { CALENDAR_ENTRY_TYPES } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";

export async function eventReminders__server__unsubscribe(p: { accountId: string; notificationType: CALENDAR_ENTRY_TYPES }) {
  // SERVER_ONLY_TOGGLE

  const { appOllieFirestoreV2: h } = getServerHelpers();

  // Make sure the accountId exists
  const ap = await h.AccountPrivate.getDoc(p.accountId);
  if (!ap) {
    throw new Error("Invalid accountId. Unable to unsubscribe from event notifications");
  }

  if (!CALENDAR_ENTRY_TYPES[p.notificationType]) {
    throw new Error("Invalid calendar entry type");
  }

  await h.AccountPrivate.update({
    id: p.accountId,
    doc: {
      settings: { eventReminderSettings: { eventTypeIntervalHours: { [p.notificationType]: 0 } } }
    }
  });
  // SERVER_ONLY_TOGGLE
}

// i18n certified - complete